<template>
  <div class="articleDetailLayout">
    <div class="articleTop">
      <div class="aritcleTopLeft fl">
        <img
          :src="allArticleData?.articleCover"
          :alt="allArticleData?.articleTitle"
          v-if="allArticleData?.articleCover"
        />
      </div>
      <div class="aritcleTopRight fl">
        <p class="aritcleTopRightTitle ">
          {{ allArticleData?.articleTitle }}
        </p>
        <!-- <p class="aritcleTopRightAbstract ">
          {{ allArticleData?.articleAbstract }}
        </p> -->
        <!-- <div class="aritcleTopRightTitleDetail">
          <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData?.sortType==1">发布时间：{{allArticleData.publishTime|formatTime}}</span>
          <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData?.sortType==2">发布时间：{{allArticleData.customTime|formatTime}}</span>
          <span v-if="showReadFlag==0||showReadFlag==''" class="seeNum">
            {{ allArticleData?.readNum || 0 }}
          </span>
        </div> -->
      </div>
    </div>
    <div class="articleCont" v-html="allArticleData?.content"></div>
    <div
      class="article-video"
      v-if="allArticleData?.articleVideoUrl"
    >
      <video
        controls
        autoplay
        :src="allArticleData?.articleVideoUrl"
        class="vdeo-play"
      >
      </video>
    </div>
    <div class="article-source" v-if="allArticleData?.copyrightType==1">
      <p>文章来源: {{ allArticleData?.source }}</p>
      <p>版权归原作者所有，如有侵权请联系我们删除</p>
    </div>
    <div class="articleBottom">
      <div class="articleBottomLeft">

        <div class="fujianItem" v-for="(item, index) in articleList" :key="index" v-show="item.name">
          <div class="fujianItemLeft">
            <p class="fujianTitle fl">附件：</p>
            <p class="yasuobaoTitle fl">
              <span>{{ item.name }}</span>
              <!--              <span>{{ allArticleData.enclosureSize }}MB</span>-->
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="item.url"
              class="fujianDownload"
              target="_blank"
            >预览</a
            >
          </div>
        </div>

        <div v-show="allArticleData.externalName" class="fujianItem friendLinkItem">
          <div class="fujianItemLeft">
            <p class="fujianTitle fl">相关链接：</p>
            <p class="yasuobaoTitle renliziyuankiaoshiLink fl">
              {{ allArticleData.externalName }}
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="allArticleData.externalUrl"
              class="lijiqianwang"
              target="_blank"
            >立即前往</a
            >
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "articleDetial",
  data() {
    return {
      articleId: "",
      allArticleData: null,
      showReadFlag:this.$route.query.showReadFlag?this.$route.query.showReadFlag:'',
      showPublishFlag:this.$route.query.showPublishFlag?this.$route.query.showPublishFlag:'',
      articleList: [],
    };
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/") +
        " " +
        val.split(" ")[1].split(":").slice(0, 2).join(":")
      );
    },
  },
  mounted() {
    this.getArctileDetail();
  },

  beforeCreate () {
    // document.querySelector('body').setAttribute('style', 'background-color:red;')

    document.querySelector('body').setAttribute('style', 'background-color:#ffffff;')
  },

  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },

  methods: {
    getArctileDetail() {
      this.$api.news
        .getArticleDetail({ id: this.$route.query.articleId })
        .then(async (res) => {
          if (res.success) {
            this.allArticleData = res.data;
            if(!this.allArticleData.enclosureUrl){
              this.articleList = JSON.parse(res.data.enclosureJson)
            } else {
              this.articleList.push({name: this.allArticleData.enclosureName,url: this.allArticleData.enclosureUrl})
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
@import "../asset/articleDetail.css";
.article-source {
  width: 100%;
  display: inline-block;
  font: 400 12px PingFangSC-Regular, PingFang SC;
  color: #999999;
  text-align: left;
  margin: 24px;
}
</style>
